import React from "react";
import Layout from '../layout/Layout';
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'

const About = () => {
    return(
        <>
            <Layout>
                <Container fluid>
                    <Row className='justify-content-center'>
                        <Col sm={12} className="p-0">
                            <div className="headers">
                                <Link to="/" className='logo'>
                                    <img src="/logo/logo.svg" alt='Screen Mirror'/>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <Row className='justify-content-center'>
                        <Col xxl={9} xl={9} lg={10} md={12}>
                            <Row className='screen-mirror-box'>
                                <Col md={12}>
                                    <div className="scr-mir-med">
                                        <iframe src="https://www.youtube.com/embed/3Y5lDTqzaBs" title="RTO Vehicle Information" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                                    </div>
                                    <div className="scr-mir-cont">
                                        <h1>About Screen Mirror</h1>
                                        <p>Using Screen Mirroring App, you can mirror your Android and Apple smartphone's screen over WiFi and access it from any browser on any other device (computer, notebook, phone or tablet) on your Wi-Fi network.</p>

                                        <p>Simply open the screenmirrorapps.com website on your target device (computer, notebook, phone, or tablet), scan the QR code from the website to your smartphone, and start the screen mirroring process. No additional software is required on the target device. </p>

                                        <p>Screen Mirror is ideal if, for example, you want to show your smartphone screen to onlookers or share photos with your family via a large monitor.</p>

                                        <h6>Before use, Please read the following:</h6>
                                        <p>To work properly, your Android and Apple device and your target device (computer, notebook, phone, or tablet) must be connected to the same Wi-Fi network (smartphone hotspot connection is also possible). In addition, an Internet connection is required. The SmartTV must support Screen Mirroring in order for the media formats used for this to work properly (All models do not work, Please email us if you need help). For more information, please see How to watch our videos on YouTube.</p>

                                        <p>Please note that Screen Mirror only broadcasts your screen, not your device's audio. Works with Google Chrome, Apple Safari, Mozilla Firefox, Etc.</p>

                                        <p>Also, please note that the transmission offset (amount of delay in the mirrored screen) is highly dependent on the processing power of your Android and Apple device as well as the WiFi connection speed. To get good performance, a device with a stable WiFi connection and a strong processor is beneficial.</p>

                                        {/* <p>Screen mirroring is not supported by all Android devices and Android versions (less than Android 5.0). If you're having problems with your device or have questions or feedback, email us at <Link to="mailto:support@screenmirroringapps.com" target="_blank">support@screenmirroringapps.com</Link></p> */}

                                        <p className="mb-0">The Screen Mirroring App contains ads that are displayed between sessions. To disable the ads, you need to upgrade to the Pro version of the Screen Mirroring app.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Layout>
        </>
    )
}

export default About
