import React from "react";
import Layout from '../layout/Layout';
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'

const Why = () => {
    return(
        <>
            <Layout>
                <Container fluid>
                    <Row className='justify-content-center'>
                        <Col sm={12} className="p-0">
                            <div className="headers">
                                <Link to="/" className='logo'>
                                    <img src="/logo/logo.svg" alt='Screen Mirror'/>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <Row className='justify-content-center'>
                        <Col xxl={9} xl={9} lg={10} md={12}>
                            <Row className='screen-mirror-box'>
                                <Col md={12}>
                                    <div className="scr-mir-med why">
                                        <img src="../img/why.png" alt="" />
                                    </div>
                                    <div className="scr-mir-cont">
                                        <h1>Why?</h1>
                                        <p className="mb-0">Your screen is transmitted to this browser via the local network using a screen mirror. As a result, for screen mirroring to work correctly, it needs to be on the same Wi-Fi network. You can also use the hotspot feature (tethering) on your smartphone to create a Wi-Fi connection. Unfortunately, neither Internet nor 3G networks support screen mirroring. In order to automatically communicate the connection configuration to the target device, the program requires an internet connection. Please verify that both devices are connected to the same network if your target device has trouble connecting. Also, if there is a firewall, check its settings. 
                                            {/* If you need any additional support, please get in touch with us via email at <Link to="mailto:support@screenmirroringapps.com">support@screenmirroringapps.com</Link> */}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Layout>
        </>
    )
}

export default Why
