import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout';
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import QRCode from 'react-qr-code';


const Home = ({socket}) => {
    const [qrData,setQrData] = useState("undefined")
    const [socketId,setSocketId] = useState("")
    socket.on('user-joined',(msg)=>{
        console.log(msg,'msg');
        setQrData(msg)
        const data = JSON.parse(atob(msg))
        setSocketId(data.socket)
    })

    socket.on('receive',(msg)=>{
        window.location.href = msg.url
    })

    const redirectPage = () =>{
        const obj = {url:"https://www.w3schools.com/",socket:socketId}
        const enc = btoa(JSON.stringify(obj))
        console.log(enc,"enc");
        socket.emit('send',enc)
    }

    useEffect(()=>{
        socket.emit('new-user-joined', {
            text: "Tushar",
            socketID: socket.id,
          });
    },[])
    return (
        <>
            <Layout>
                <Container fluid>
                    <Row className='justify-content-center'>
                        <Col sm={12} className="p-0">
                            <div className="headers">
                                <Link to="/" className='logo'>
                                    <img src="/logo/logo.svg"  alt='Screen Mirror'/>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <Row className='justify-content-center'>
                        <Col xxl={9} xl={9} lg={11} md={12}>
                            <Row className='screen-mirror-box'>
                                <Col xxl={8} xl={12}>
                                    <Row className="align-items-center">
                                        <Col lg={6} className="text-center">
                                            <div className='ps-lg-5'>
                                                <div className='screen-mirror-media'>
                                                    <img src="img/mockup.svg" alt="" />
                                                    <div className='qr-code'>
                                                        <QRCode className='mt-4' size={200} value={qrData} viewBox="0 0 256 256" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6} className="p-lg-0">
                                            <div className="screen-mirror-content">
                                                <h1>Scan QR code</h1>
                                                <p>Scan QR code to start screen mirroring</p>
                                                <div>
                                                    <Link className="app-store-btn" to="https://play.google.com/store/apps/details?id=com.screen.mirror.cast.share.tv.device.app" target="_blank">
                                                        <img alt="Get it on Google Play" src="img/play-store.svg"/>
                                                    </Link>
                                                    <Link className="app-store-btn" to="https://apps.apple.com/us/app/screen-mirroring-sharing/id6446764475" target="_blank">
                                                        <img alt="Get it on App Store" src="img/app-store.svg"/>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={12} className="p-0">
                                            <div className='screen-mirror-info'>
                                                <ul>
                                                    <li>Verify that this device (computer, notebook, phone, or tablet)  and your smartphone are connected to the same Wi-Fi network. <Link to="/why">Why?</Link></li>
                                                    <li>To start Screen Mirroring in <Link to="https://play.google.com/store/apps/details?id=com.screen.mirror.cast.share.tv.device.app" target="_blank">Android device</Link> & <Link to="https://apps.apple.com/us/app/screen-mirroring-sharing/id6446764475" target="_blank">iOS device</Link> , open the Screen Mirroring App on your Android / iOS smartphone and scan this QR code.</li>
                                                    <li>How does screen mirroring works? For The <Link to="/about">More Information Watch Video</Link></li>
                                                </ul>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xxl={4} xl={12} className="align-self-end screen-mirror-view">
                                    <Row>
                                        <Col lg={12}>
                                            <div className='pe-lg-5'>
                                                <img src="img/mirrorview.png" className='img-fluid' alt="" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Link Link to="/about" className="btn-info"><i className='bx bx-question-mark'></i></Link>
            </Layout>
        </>
  )
}

export default Home