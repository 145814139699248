import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './bootstrap.css';
import 'boxicons/css/boxicons.css';
import './App.css';
import socketIO from 'socket.io-client';
import Home from './pages/Home';
import About from './pages/About';
import Why from './pages/Why';
import dotenv from 'react-dotenv';


const socket = socketIO.connect(process.env.REACT_APP_SOCKET_URL);

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home socket={socket}/>} />
          <Route path="/About" element={<About/>} />
          <Route path="/Why" element={<Why/>} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App;
